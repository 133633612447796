.SubTitulo-Tarjeta{
    font-size: 4vh;
    text-align: center;
}

.Contenedor-Texto-Bienvenida{
    margin: 7vh 5% 5% 0;
}

.Texto-Titulo-Bienvenida{
    font-size: 4vh;
    margin-bottom: 1vh;
}

.Texto-Subtitulo-Bienvenida{
    font-size: 2.5vh;
}

.Texto-Audio{
    margin: 0 2vh;
}

.Imagen-Navbar{
    height: 100%;
    width: 40vh;
    padding: 2vh 0 0 3vh;
}

.Contenedor-Navbar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
    height: 12vh;
    background-color: white;
}

.Contenedor-Video{
    width: 100%;
    height: 70vh;
}

.Imagen-Tarjeta{
    width: 60%;
    height: auto;
}

.Contenedor-Fila{
    padding: 4vh 8vh 0 8vh !important;
    display: flex;
    justify-content: center;
}

.Contenedor-Menu{
    width: 12vh;
    height: auto;
    background-color: #D9E3FF;
    border-radius: 0 0 0 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Contenedor-Fila-Principal{
    padding: 4vh 5vh 0 5vh !important;
    margin-bottom: 5vh;
}

.Contenedor-Imagen{
    display: flex;
    justify-content: end;
    width: 100vh;
}

.Contenedor-Texto-Tarjeta{
    padding: 3vh 6vh 0 6vh;
    font-size: 2.5vh;
}

.Contenedor-Tarjeta{
    margin: 3vh 6vh;
    border-radius: 3vh;
}

.Flecha-Audio-Inferior{
    width: auto;
    height: 1.5vh;
}

.Boton-Audio{
    width: 52%;
    margin: 4vh 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vh 3vh;
    background-color: #D9E3FF;
    border-radius: 6vh;
    box-shadow: 0px .5vh .5vh rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.Contenedor-Beneficios-Uno{
    background-color: #FFD9D9;
}
.Contenedor-Beneficios-Dos{
    background-color: #FFF7D9;
}
.Contenedor-Beneficios-Tres{
    background-color: #D9F8FF;
}
.Contenedor-Encontraremos-Uno{
    background-color: #D9E3FF;
}
.Contenedor-Encontraremos-Dos{
    background-color: #D9FFE3;
}
.Contenedor-Encontraremos-Tres{
    background-color: #FFE1CB;
}

.Boton-Audio-Inferior{
    width: 40vh;
    margin: 4vh 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vh 3vh;
    background-color: #D9E3FF;
    border-radius: 6vh;
    box-shadow: 0px .5vh .5vh rgba(0, 0, 0, 0.25);
    cursor: pointer;
}


@media screen and (max-width: 1120px) {
    .Boton-Audio{
        width: 55%;
    }
}
@media screen and (max-width: 1024px) {
    .Contenedor-Fila{
        padding: 0vh 0vh 0 0vh !important;
    }
    .Boton-Audio{
        width: 60%;
    }
}
@media screen and (max-width: 991px) {
    .Contenedor-Fila{
        padding: 0vh 0vh 0 0vh !important;
    }
    .Contenedor-Tarjeta{
        margin: 3vh 5vh;
    }
    .Boton-Audio{
        width: 30%;
    }
    .Contenedor-Fila-Principal{
        margin-bottom: 0;
        padding-top: 0 !important;
    }

}

@media screen and (max-width: 575px) {
    .Boton-Audio{
        width: 100%;
    }
    .Contenedor-Imagen{
        display: flex;
        justify-content: end;
    }
}
@media screen and (max-width: 1440px) {
    .Boton-Audio{
        width: 50%;
    }

}
@media screen and (max-width: 1280px) {
    .Boton-Audio{
        width: 60%;
    }

}
@media screen and (max-width: 911px) {
    .Boton-Audio{
        width: 100%;
    }

}
@media screen and (max-width: 787px) {
    .Boton-Audio{
        width: 55%;
    }
}
@media screen and (max-width: 787px) {
    .Boton-Audio{
        width: 35%;
    }
}
@media screen and (max-width: 781px) {
    .Boton-Audio{
        width: 35%;
    }
}
@media screen and (max-width: 768px) {
    .Contenedor-Fila{
        margin-top: 0 !important;
    }
    .Boton-Audio{
        width: 30%;
    }
    .Contenedor-Texto-Tarjeta{
        padding: 3vh 6vh 2vh 6vh;
    }
    .Contenedor-Texto-Bienvenida{
        margin: 3vh 5% 5% 0;
    }
}
@media screen and (max-width: 546px) {
    .Boton-Audio{
        width: 40%;
        height: auto;
    }
}
@media screen and (max-width: 269px) {
    .Flecha-Audio-Inferior{
        height: 1vh;
    }
    .Texto-Audio{
        margin: 0 1vh;
    }
}
@media screen and (max-width: 684px) {
    .Boton-Audio{
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 425px) {
    .Boton-Audio{
        width: 90%;
        height: auto;
    }

    .Boton-Audio-Inferior{
        width: 90%;
    }
    .Contenedor-Video{
        height: 60vh;
    }
    .Imagen-Navbar{
        height: 100%;
        width: 30vh;
    }
    .Imagen-Navbar{
        height: 100%;
        width: 25vh;
    }
}
